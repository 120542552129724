import Button from "shared/button/button";
import "./App.css";
import React, { useState } from "react";
import {
  primaryButtonHoveredStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";
import { LARGE } from "shared/buttonSize";
import withSplashScreen from "components/withSplashScreen/withSplashScreen";
import DoctorsCard from "shared/doctors/doctorsCard/doctorsCard";
import InputField from "shared/inputField/inputField";
import MobileNumberSreen from "components/onboarding/mobileNumberScreen";
import OtpInput from "shared/otpInput/otpInput";
import VerifyOtpScreen from "components/onboarding/verifyOtpScreen";
import SysRouter from "sysRouter";
import * as bootstrap from "bootstrap";
import { ToastMsg } from "shared/toast/toastMsg";

function App() {
  const [otp, setOtp] = useState("");
  const onChange = (value: string) => setOtp(value);

  return (
    <div className="App p-3">
      <ToastMsg />
      <SysRouter />
      {/* Kapeefit Logos */}
      {/* <Button
        isLoading={true}
        size={LARGE}
        disabled={true}
        style={primaryButtonStyle}
        hoveredStyle={primaryButtonHoveredStyle}
      >Continue Shopping</Button> */}
      {/* <DoctorsCard /> */}
      {/* <InputField placeholder="Fooasdf" /> */}
      {/* <MobileNumberSreen /> */}
      {/* <VerifyOtpScreen /> */}
      {/* <OtpInput value={otp} otpLength={6} onChange={onChange} />  */}
    </div>
  );
}

export default withSplashScreen(App);
