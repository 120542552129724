import React, { useState } from "react";
import Styles from "./onboarding.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "shared/button/button";
import {
  primaryButtonHoveredStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";
import { ACTIVE, ERROR, LARGE, LEFT, VALID } from "shared/constants";
import Heading from "shared/heading/heading";
import InputField from "shared/inputField/inputField";
import { UtilAPI } from "apis/utilAPI";
import { ToastMessage } from "shared/toast/toastMsg";
import { ProfileAPI } from "apis/profileAPI";

export default function PersonalDetails() {
  const [name, setName] = useState<{ value: string; error?: boolean }>({
    value: "",
  });
  const [dob, setDob] = useState<{ value: string; error?: boolean }>({
    value: "",
  });
  const [gender, setGender] = useState<{ value: string; error?: boolean }>({
    value: "",
  });
  const [referral, setReferral] = useState<{ value: string; error?: boolean }>({
    value: "",
  });
  const { state } = useLocation();
  const navigate = useNavigate();

  const validateDob = () => {
    setDob({ value: dob.value, error: false });
  };
  const validateName = () => {
    if (name.value.length < 2) setName({ value: name.value, error: true });
    else setName({ value: name.value, error: false });
  };
  const validateGender = () => {
    if (gender.value === "") setGender({ value: gender.value, error: true });
    else setGender({ value: gender.value, error: false });
  };

  const saveUser = () => {
    if (referral.value.length > 0) {
      const isReferralValid = verifyReferralCode();
      if (!isReferralValid) {
        return;
      }
    }
    registerUser();
  };

  const registerUser = () => {
    const profileData = {
      name: name.value,
      dob: dob.value,
      gender: gender.value,
      mobile: state.mobile,
    };
    console.log(profileData);
    ProfileAPI.editProfileData(profileData)
      .then((response) => {
        if (response.success === true) {
          navigate("/registrationStatusScreen", {
            replace: true,
            state: { status: true },
          });
        } else {
          ToastMessage.show("Invalid Data!");
        }
      })
      .catch((error) => {
        ToastMessage.show("Something went wrong. Please try again later!");
      });
  };

  const verifyReferralCode = (): Boolean => {
    const referralCode = referral.value;
    UtilAPI.verifReferralCode(referralCode)
      .then((response) => {
        if (response.success === true) {
          return true;
        } else {
          ToastMessage.show("Invalid Referral Code");
          return false;
        }
      })
      .catch((error) => {
        ToastMessage.show("Error Occured. Pleas Try again later!");
        return false;
      });
    return false;
  };

  return (
    <div className={`${Styles.pageContainer} pt-5`}>
      <div
        className={`${Styles.progressContainer} d-flex justify-content-between mb-3`}
      >
        <div className={`${Styles.progressBar}`}> </div>
        <div className={`${Styles.progressBar} ${Styles.progressBarActive}`}>
          {" "}
        </div>
      </div>
      <Heading
        align={LEFT}
        title="Enter Your Name, Gender & Age"
        width={"100%"}
      />
      <div className="vstack gap-3 mt-3">
        <InputField
          placeholder="Enter your name"
          autoFocus
          onChange={(e: { target: { value: any } }) =>
            setName({ value: e.target.value })
          }
          onBlur={validateName}
          errorText="Invalid Name"
          isValidState={
            typeof name.error === "undefined"
              ? ACTIVE
              : name.error
              ? ERROR
              : VALID
          }
          value={name.value}
        />
        <InputField
          placeholder="Select Date of Birth"
          onChange={(e: { target: { value: any } }) =>
            setDob({ value: e.target.value })
          }
          onBlur={validateDob}
          errorText="Invalid DOB"
          isValidState={
            typeof dob.error === "undefined"
              ? ACTIVE
              : dob.error
              ? ERROR
              : VALID
          }
          value={dob.value.length !== 0 ? dob.value : ""}
          type="date"
        />
        <select
          className={` ${Styles.dropdown} form-control form-control-lg`}
          defaultValue={""}
          onChange={(e) => setGender({ value: e.target.value })}
          onBlur={validateGender}
        >
          <option value="" disabled>
            -- Select Gender --
          </option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
      </div>
      <div className={`${Styles.textBetweenLine}`}>
        <span>Do you have a Referral code?</span>
      </div>

      <InputField
        placeholder="Enter your referral code here"
        onChange={(e: { target: { value: any } }) =>
          setReferral({ value: e.target.value })
        }
        value={referral.value.length !== 0 ? referral.value : ""}
      />
      <div className={`${Styles.miniText} text-start mt-3`}>
        Following information will be used to improve the search results &
        keeping data secure for future changes to the app.
      </div>
      {/* Bottom Button */}
      <div className={`${Styles.alignBottom2}`} onClick={saveUser}>
        {/* <Link to="/registrationStatusScreen" state={{ status: true }}> */}
        <Button
          disabled={false}
          style={primaryButtonStyle}
          hoveredStyle={primaryButtonHoveredStyle}
          size={LARGE}
        >
          Next
        </Button>
        {/* </Link> */}
      </div>
    </div>
  );
}
