import React from "react";
import Styles from "./notificationsScreen.module.scss";
import AppBar from "shared/appBar/appBar";

export default function NotificationsScreen() {
  return (
    <>
      <AppBar title="Notifications" />
      <div>No new notifications</div>
    </>
  );
}
