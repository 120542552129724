import OtpInput from "shared/otpInput/otpInput";
import React, { useEffect, useState } from "react";
import { Form, useLocation, useNavigate } from "react-router-dom";
import { LARGE } from "shared/buttonSize";
import {
  MIDDLE,
  UserDefaultImage_Female,
  UserDefaultImage_Male,
} from "shared/constants";
import Heading from "shared/heading/heading";
import Styles from "./onboarding.module.scss";
import Button from "shared/button/button";
import {
  primaryButtonHoveredStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";
import { AuthAPI } from "apis/authAPI";
import { generateOtpRequest, singupSignInRequest } from "apis/interface";
import { ToastMessage } from "shared/toast/toastMsg";
import TimerCount from "utils/timer";

export default function VerifyOtpScreen({}) {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState<number>(30);
  const [resentOtpCount, setResendOtpCount] = useState(2);
  const navigate = useNavigate();
  const onChange = (value: string) => setOtp(value);
  const { state } = useLocation();

  /**
   *    Resend OTP - API Call
   *  @param e: Current Event. | Not required during function call
   */
  const resendOtp = () => {
    // Reduce the allowed OTP count by 1
    setResendOtpCount(resentOtpCount - 1);
    // get OTP request Body
    const requestBody: generateOtpRequest = {
      mobile: state.mobile.toString(),
      verificationCode: "hxhxhxhxhxx",
    };

    // Get OTP API call
    AuthAPI.getOTP(requestBody)
      .then((response) => {
        if (response.success === true) {
          if (resentOtpCount > 0) {
            // startOtpTimer();
          }
          ToastMessage.show("OTP sent successfully.");
        } else {
          ToastMessage.show("OTP could not be sent. Please try again.");
          return;
        }
      })
      .catch((error) => {
        console.log("Error sending OTP", error);
      });
  };

  /**
   *    Verify If Mobile Number
   *    is registered or Not
   *    If not -> Take personal Details.
   */

  /**
   *    Verify OTP - API Call
   */
  const verifyOtpApi = (e: any) => {
    e.preventDefault();
    setLoading(true);

    // Verify OTP Request Body
    const requestBody: singupSignInRequest = {
      name: "kapeefit_user",
      dob: "01-01-1990",
      gender: "male",
      mobile: state.mobile.toString(),
      fcmToken: "aohslkdjhafljks dfljhal sd",
      deviceID: "svsvsvsvsv",
      platform: navigator.platform,
      appVersion: "v1.0.0",
      otp: otp,
    };

    // Verify OTP API Call
    AuthAPI.singupSignIn(requestBody)
      .then((response) => {
        if (response.success === true) {
          const profileData = {
            id: response.data._id,
            image:
              response.data.image && response.data.image.length > 0
                ? response.data.image
                : response.data.gender && response.data.gender === "male"
                ? UserDefaultImage_Male
                : UserDefaultImage_Female,
            access_token: response.data.token,
            mobile: response.data.mobile,
            name: response.data.name,
            gender: response.data.gender,
            email: response.data.email,
            dob: response.data.dob,
          };
          localStorage.setItem("profileData", JSON.stringify(profileData));
          profileData.name === "kapeefit_user" || profileData.name === "   "
            ? navigate("/personalDetails", {
                replace: true,
                state: { mobile: profileData.mobile },
              })
            : navigate("/", { replace: true });
        } else {
          ToastMessage.show("Wrong OTP.");
        }
      })
      .catch((error) => {
        ToastMessage.show("Error Verifying Mobile. Please try again later.");
      });
    setTimeout(() => {
      // console.log("OTP is being verified: ", otp);
      setLoading(false);
      // navigate("/personalDetails", { replace: true });
    }, 5000);
  };

  /**
   *    Countdown Timer
   */
  const startOTPTimer = () => {
    setTimeout(() => {
      if (seconds > 0) {
        setSeconds((sec) => sec - 1);
      }
    }, 1000);
  };

  useEffect(() => {
    startOTPTimer();
  });
  return (
    <div className="mt-5">
      <Heading size={LARGE} title="Verify Phone Number" align={MIDDLE} />
      <div className={`${Styles.subHeading} m-3`}>
        {"Enter the OTP sent to +91-" + state.mobile}
      </div>
      <form onSubmit={verifyOtpApi}>
        <OtpInput value={otp} otpLength={6} onChange={onChange} />
        {seconds === 0 ? (
          <div
            className={`${Styles.subHeading} mt-3 text-start`}
            style={{
              width: "280px",
              margin: "auto",
              textAlign: "left",
              color: "#008080",
              fontWeight: "bold",
            }}
            onClick={() => {
              if (resentOtpCount > 0) {
                resendOtp();
                setSeconds(30);
                startOTPTimer();
              }
            }}
          >
            {resentOtpCount > 0 ? "Resend OTP" : "Max Limit Reached!"}
          </div>
        ) : (
          <div
            className={`${Styles.subHeading} mt-3 text-start`}
            style={{
              width: "280px",
              margin: "auto",
              textAlign: "left",
            }}
          >{`Resend OTP in: ${seconds}`}</div>
        )}
        <br></br>
        <Button
          style={primaryButtonStyle}
          hoveredStyle={primaryButtonHoveredStyle}
          size={LARGE}
          isLoading={loading}
          disabled={loading ?? false}
        >
          Verify
        </Button>
      </form>
    </div>
  );
}
