import React from "react";
import Styles from "./vitals.module.scss";
import AppBar from "shared/appBar/appBar";
import { useLocation } from "react-router-dom";
import InputFieldWithLabel from "shared/inputFieldWithLabel/inputFieldWithLabel";
import Button from "shared/button/button";
import { primaryButtonStyle } from "shared/buttonStyles";
import { LARGE } from "shared/constants";

const Vitals = () => {
  const { state } = useLocation();
  return (
    <div className={`${Styles.vitalsMainContainer}`}>
      <AppBar title={"Vitals"} />
      <div className={`${Styles.heading} mt-4`}>
        Vitals For: <span>{state.name}</span>
      </div>

      <div className={`${Styles.inputFieldsContainer} mt-3`}>
        <div className={"mb-3"}>
          <InputFieldWithLabel
            typeOfInput={"number"}
            placeholderText={"Height (cm)"}
          />
        </div>
        <div className={"mb-3"}>
          <InputFieldWithLabel
            typeOfInput={"number"}
            placeholderText={"Weight (kg)"}
          />
        </div>
        <div className={"mb-3"}>
          <InputFieldWithLabel
            typeOfInput={"number"}
            placeholderText={"Blood Sugar (fasting)"}
          />
        </div>
        <div className={"mb-3"}>
          <InputFieldWithLabel
            typeOfInput={"number"}
            placeholderText={"Blood Sugar (post-prandial)"}
          />
        </div>
        <h3 className={`${Styles.headingWithLine}mt-4`}>&nbsp;&nbsp;Blood Pressure (BP)&nbsp;&nbsp;</h3>
        {/* BP 2 way input */}
        <div className={`${Styles.twoWayInput} d-flex`}>
          <input
            type="number"
            name=""
            id=""
            placeholder={"systolic"}
            min={111}
          />
          <span>/</span>
          <input type="number" name="" id="" placeholder={"diastolic"} />
        </div>
      </div>
      <div className={`${Styles.bottomButtonContainer}`}>
        <Button style={primaryButtonStyle} size={LARGE}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default Vitals;
