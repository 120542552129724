import React from "react";
import Styles from "./pathologyBookingsScreen.module.scss";
import AppBar from "shared/appBar/appBar";

export default function PathologyBookingsScreen() {
  return (
    <>
      <AppBar title="Lab Test Order" />
    </>
  );
}
