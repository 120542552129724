import React, { useEffect, useState } from "react";
import Symptoms from "shared/symptoms/symptoms";
import Styles from "./home.module.scss";
import ProductCard from "shared/product/productCard";
import CardView from "shared/cardView/cardView";
import { Link, useNavigate } from "react-router-dom";
import { LARGE } from "shared/buttonSize";
import Heading from "shared/heading/heading";
import {
  DiseaseDefaultImage,
  DoctorDefaultImage_Female,
  DoctorDefaultImage_Male,
  HOME,
  Icon_BuyMedicinesAndEssentials,
  Icon_OneClickAppointment,
  LEFT,
  SMALL,
} from "shared/constants";
import DoctorsCard from "shared/doctors/doctorsCard/doctorsCard";
import { BottomNavigation } from "shared/bottomNavigation/bottomNavigation";
import { SymptomsAPI } from "apis/symptomsAPI";
import Loading from "shared/loading/loading";
import { PRIMARYGREEN } from "shared/colors";
import TopNavigation from "shared/topNavigation/topNavigation";
import IconArrowButton from "shared/iconArrowButton/iconArrowButton";
import CarouselSlider from "shared/carouselSystem/carouselSlider";
import { ContentAPI } from "apis/contentAPI";
import ChipCardView from "shared/chipCardView/chipCardView";

export default function Home() {
  const [filters, setFilters] = useState({
    gender: ["male", "female", "other"],
    symptoms: [],
  });
  const [symptomsData, setSymptomsData] = useState([]);
  const [popularDoctors, setPopularDoctors] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [medicalTests, setMedicalTests] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [banners, setBanners] = useState<any>({
    PatientHomeTop: [],
    PatientHomeMid: [],
    PatientHomeBottom: [],
  });
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();

  function getSymptomsData() {
    SymptomsAPI.getAll()
      .then((response) => {
        //  Check if status is true proceed to create filtered object
        if (response.data.status === true) {
          const symptomNames = response.data.data.map((symptom: any) => {
            return symptom.name;
          });
          setFilters({ ...filters, symptoms: symptomNames });
        }
      })
      .catch((e) => {
        return "Error while fetching symptoms";
      });
  }

  function getHomePageData() {
    ContentAPI.getHomePage().then((response) => {
      if (response?.success === true) {
        Object.keys(response.data).map((group) => {
          // Case to Handel Banners seprately as it is an Object and others are array
          if (group === "bannersByPosition") {
            let topBanners: string[] = [];
            let midBanners: string[] = [];
            let bottomBanners: string[] = [];
            for (const bannerGroup in response.data[group]) {
              response.data[group][bannerGroup].map((banner) => {
                if (bannerGroup === "PatientHomeTopBanner") {
                  topBanners.push(banner.image);
                } else if (bannerGroup === "PatientHomeMidBanner") {
                  midBanners.push(banner.image);
                } else if (bannerGroup === "PatientHomeBottomBanner") {
                  bottomBanners.push(banner.image);
                }
                return true;
              });
            }
            setBanners({
              PatientHomeTop: topBanners,
              PatientHomeBottom: bottomBanners,
              PatientHomeMid: midBanners,
            });
          }
          const dataArray =
            response.data[group].length > 0
              ? response.data[group].map((info: any) => {
                  if (group === "symptom") {
                    return {
                      title: info.name,
                      image: info.image,
                    };
                  } else if (group === "medicalTest") {
                    return {
                      image: info.image,
                      title: info.name,
                    };
                  } else if (group === "featuredProductList") {
                    return {
                      id: info._id,
                      title: info.name,
                      thumbnail:
                        info.images && info.images.length > 0
                          ? info.images[0].src
                          : null,
                      regularPrice: Number(info.regular_price),
                      sellingPrice: Number(info.sale_price),
                      ratingValue: Number(info.rating_count).toFixed(1),
                    };
                  } else if (group === "popularDocterList") {
                    return {
                      id: info._id,
                      title: info.name,
                      profile_image:
                        info.profileImage && info.profileImage.length > 0
                          ? info.profileImage
                          : info.gender && info.gender === "Male"
                          ? DoctorDefaultImage_Male
                          : DoctorDefaultImage_Female,
                      specialization: info.specialization,
                      experience: info.experience,
                      rating: Number(info.rating).toFixed(1),
                      patients:
                        info.appointmentCount &&
                        info.appointmentCount.length > 0
                          ? info.appointmentCount[0].total
                          : 0,
                      reviews:
                        info.reviewerCount && info.reviewerCount.length > 0
                          ? info.reviewerCount.totle
                          : 0,
                      gender: info.gender ?? "Other",
                    };
                  }
                  return true;
                })
              : "";
          if (group === "symptom") {
            setSymptomsData(dataArray);
          } else if (group === "medicalTest") {
            setMedicalTests(dataArray);
          } else if (group === "featuredProductList") {
            setFeaturedProducts(dataArray);
          } else if (group === "popularDocterList") {
            setPopularDoctors(dataArray);
          }
          return true;
        });
      }
    });
  }

  function getBlogs() {
    ContentAPI.getTopBlogs().then((response) => {
      if (response?.success === true) {
        const data =
          response.data.length > 0
            ? response.data.map((blog) => {
                return {
                  id: blog.id,
                  title: blog.title,
                  image: blog.featuredImage,
                };
              })
            : [];
        setBlogs(data);
      }
    });
  }

  function getShopCategories() {
    ContentAPI.getShopCategories()
      .then((response) => {
        if (response?.success === true) {
          const categories = response.data.map((category: any) => {
            return {
              id: category.id,
              name: category.name,
              image:
                category.image && category.image !== ""
                  ? category.image
                  : DiseaseDefaultImage,
            };
          });
          setBrandList(categories);
        } else {
          console.error("Error fetching Categories");
        }
      })
      .catch((error) => {
        console.error("Error fetching Categories", error);
      });
  }

  /**
   *    Show Doctor for selected Symptom
   */
  function showDoctorsForSelectedSymptom(symptomId, symptomTitle) {
    console.log(symptomId, symptomTitle);
    navigate("/consult", { state: { symptom: symptomTitle } });
  }

  useEffect(() => {
    getHomePageData();
    getBlogs();
    setTimeout(() => {
      getSymptomsData();
    }, 1000);
    getShopCategories();
  }, []);
  return (
    <div>
      <TopNavigation />

      {/* Symptoms */}
      <div className="d-flex justify-content-between">
        <div>
          <Heading size={LARGE} align={LEFT} title="Symptoms" width={"100%"} />
        </div>
        <div className={Styles.rightViewMore}>
          <Link to={"/filter"} state={{ filters, activeFilter: "symptoms" }}>
            More
          </Link>
        </div>
      </div>
      <div className={`${Styles.symptomsContainer} mt-3 mb-5`}>
        <Symptoms
          symptoms={symptomsData}
          style={`d-flex flex-nowrap`}
          sendItemIdToParent={showDoctorsForSelectedSymptom}
        />
      </div>

      {banners.PatientHomeTop.length > 0 ? (
        <CarouselSlider imagesList={banners.PatientHomeTop} />
      ) : (
        <Loading loadingColor={"#E85222"} />
      )}

      {/* Consultation Book Button */}
      <IconArrowButton
        goto="/consult"
        title="One-Click Appointment"
        iconImage={Icon_OneClickAppointment.default}
      />
      <IconArrowButton
        goto="/medicine"
        title="Buy Medicines and Essentials"
        iconImage={Icon_BuyMedicinesAndEssentials.default}
      />

      {/* Medical Tests */}
      <div className="mt-5"></div>
      <div className={"d-flex justify-content-between"}>
        <div>
          <Heading
            size={LARGE}
            align={LEFT}
            title="Medical tests"
            width={"100%"}
          />
        </div>
        <div className={Styles.rightViewMore}>
          <Link to={"/shop"} state={{ shopType: "labtest" }}>
            More
          </Link>
        </div>
      </div>

      <div className={Styles.cardsContainer}>
        {medicalTests.length > 0 ? (
          <CardView data={medicalTests} style={`d-flex flex-nowrap mt-4`} />
        ) : (
          <Loading loadingColor={"#E85222"} />
        )}
      </div>

      {/* Popular Doctors */}
      <div className="mt-5"></div>
      <Heading
        size={LARGE}
        align={LEFT}
        title="Popular Doctors"
        width={"100%"}
      />
      <div className="mt-3"></div>
      {popularDoctors && popularDoctors.length > 0 ? (
        popularDoctors.slice(0, 6).map((doctor: any) => {
          return (
            <Link to="/consult/booking" key={doctor.id} state={{ doctor }}>
              <DoctorsCard
                key={doctor.id}
                doctorName={doctor.title}
                doctorDesignation={doctor.specialization}
                doctorImage={doctor.profile_image}
                experience={doctor.experience}
                patientsTreated={doctor.patients}
                reviews={doctor.reviews}
                rating={doctor.rating}
              />
            </Link>
          );
        })
      ) : (
        <div>
          <Loading loadingColor={PRIMARYGREEN} width={"8px"} height={"8px"} />
        </div>
      )}

      {/* Carousel */}
      <div className={"mt-4"}>
        {banners.PatientHomeMid.length > 0 ? (
          <CarouselSlider imagesList={banners.PatientHomeMid} />
        ) : (
          <Loading loadingColor={"#E85222"} />
        )}
      </div>

      {/* Shop By Health Conditions */}
      <div className={"mt-5"}></div>
      <section className={Styles.shopByhealthContainer}>
        <div className="d-flex justify-content-between">
          <div>
            <Heading
              size={SMALL}
              align={LEFT}
              title="Shop By Health Conditions"
            />
          </div>
          <div className={Styles.rightViewMore}>
            <Link to={"/filter"} state={{ filters, activeFilter: "symptoms" }}>
              More
            </Link>
          </div>
        </div>
        <div className={`${Styles.cardsContainer} ${Styles.mini} d-flex mt-2`}>
          {symptomsData.slice(0, 10).map((brand: any, index) => {
            return (
              <ChipCardView
                key={index}
                title={brand.title}
                imageURI={
                  brand.image && brand.image !== ""
                    ? brand.image
                    : DiseaseDefaultImage
                }
              />
            );
          })}
        </div>
      </section>

      {/* Products */}
      <div className="mt-3"></div>
      <div className="d-flex">
        <Heading size={LARGE} align={LEFT} title="Products" width={"70%"} />
        <div className={Styles.rightViewMore}>
          <Link to={"/shop"}>View More</Link>
        </div>
      </div>
      <div className="mt-3"></div>
      <ProductCard productsList={featuredProducts} />

      {/* Carousel */}
      <div className={"mt-4"}>
        {banners.PatientHomeBottom.length > 0 ? (
          <CarouselSlider imagesList={banners.PatientHomeBottom} />
        ) : (
          <Loading loadingColor={"#E85222"} />
        )}
      </div>

      {/* Shop By Category */}
      <div className={"mt-5"}></div>
      <section className={Styles.shopByhealthContainer}>
        <div className="d-flex justify-content-between">
          <div>
            <Heading size={SMALL} align={LEFT} title="Shop By Category" />
          </div>
          <div className={Styles.rightViewMore}>
            <Link to={"/shop"}>View More</Link>
          </div>
        </div>
        <div className={`${Styles.cardsContainer} ${Styles.mini} d-flex mt-2`}>
          {brandList &&
            brandList.map((brand: any, index) => {
              return (
                <ChipCardView
                  key={index}
                  title={brand.name}
                  imageURI={brand.image}
                />
              );
            })}
        </div>
      </section>

      {/* Blogs */}
      <div className={"mt-3"}></div>
      <section className={Styles.blogsContainer}>
        <div className="d-flex justify-content-between">
          <div>
            <Heading size={SMALL} align={LEFT} title="Blogs" />
          </div>
          <div className={Styles.rightViewMore}>
            <Link to={"/blogs"}>View More</Link>
          </div>
        </div>
        <div className={`${Styles.cardsContainer} ${Styles.high}`}>
          {blogs.length > 0 ? (
            <CardView data={blogs} style={`d-flex flex-nowrap mt-4`} />
          ) : (
            <Loading loadingColor={"#E85222"} />
          )}
        </div>
      </section>

      <div className={Styles.extraSpace}></div>
      {/* Bottom Navigtion */}
      <BottomNavigation navigationSelection={HOME} />
    </div>
  );
}
