import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Styles from "./product.module.scss";
import Button from "shared/button/button";
import { LARGE } from "shared/buttonSize";
import {
  primaryButtonStyle,
  primaryButtonHoveredStyle,
  primaryButtonLightStyle,
  primaryButtonDisabledStyle,
} from "shared/buttonStyles";
import Heading from "shared/heading/heading";
import { LEFT, ProductDefaultImage, SMALL } from "shared/constants";
import ProductMiniCard from "./productMiniCard";
import AppBar from "shared/appBar/appBar";
import { ProductsAPI } from "apis/productsAPI";
import { WishlistAPI } from "apis/wishListAPI";
import { getProfileDataFromLocal } from "utils/utils";
import { CartAPI } from "apis/cartAPI";
import { ToastMessage } from "shared/toast/toastMsg";
import Loading from "shared/loading/loading";
import Rating from "shared/rating/rating";
import { error } from "console";
import ProductCarousel from "shared/productCarousel/productCarousel";
const ProductImage2 = require("../../assets/images/registration_confirm.png");
const ProductImage3 = require("../../assets/images/registration_failed.png");

export default function ProductView() {
  const { id } = useParams();
  const [productDetails, setProductDetails] = useState<any>({});
  const [productImages, setProductImages] = useState([]);
  const [isWishlisted, setIsWishlisted] = useState(false);
  const [isProductInCart, setIsProductInCart] = useState(false);
  const [similarProducts, setSimilarProducts] = useState([]);
  const navigate = useNavigate();

  /**
   *    Fetch product details from source
   */
  function getProductDetails() {
    ProductsAPI.getProductById(id)
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          let info = response.data.data;
          // console.log(info);
          let productInfo = {
            id: info._id,
            productTitle: info.name,
            regularPrice: info.regular_price,
            sellingPrice: info.price,
            manufactureInfo: info.short_description,
            detailedInfo: info.description,
            stockStatus: info.stock_status,
            ratingValue: Number(info.average_rating),
            ratingCount: info.rating_count,
            reviews: info.reviews_allowed,
          };
          setProductImages(info.images.map((e) => e.src));
          // Update whilist button
          setProductDetails(productInfo);
          // Get Similar Products
          fetchSimilarProducts();
        }
      })
      .catch((err) => {
        return "Error fetching Product details.";
      });
  }

  // Get similar Products data
  function fetchSimilarProducts() {
    ProductsAPI.getRelatedProductsById(id)
      .then((response) => {
        // Need to check for status code as woo_coomerce API
        if (response.success === true) {
          const productsList = response.data.map((product) => {
            return {
              id: product._id,
              title: product.name,
              sellingPrice: product.sale_price,
              regularPrice: product.regular_price,
              ratingValue: Number(product.average_rating),
              ratingCount: product.rating_count,
              thumbnail:
                product.images.length > 0 ? product.images[0].src : null,
              stockStatus: product.stock_status,
            };
          });
          setSimilarProducts(productsList);
        }
      })
      .catch((err) => {
        return "Error fetching Similar Products Data";
      });
  }

  //  Add Product TO wishList
  function addProductToWishlist() {
    WishlistAPI.addProduct(id)
      .then((response) => {
        if (response.success === true) {
          setIsWishlisted(true);
          return ToastMessage.show("Added to Wishlist");
        }
      })
      .catch((e) => {
        return "Error: Item could not be added to wishlist.";
      });
  }

  //  Remove product from Wishlist
  function removeProductFromWishlist() {
    const formData = new FormData();
    formData.append("productId", id!);
    formData.append("type", "product");
    WishlistAPI.deleteProduct(formData, "multipart/form-data")
      .then((response) => {
        if (response.success === true) {
          setIsWishlisted(false);
          return ToastMessage.show("Removed from Wishlist");
        }
      })
      .catch((error) => {
        return ToastMessage.show(
          "Error product not removed from Wishlist.",
          error
        );
      });
  }

  /**
   *    Handle "Add to Cart" button click
   */
  function addProductToCart(e, productId) {
    e.preventDefault();
    const addProductToCartReqBody = {
      productId,
      patientId: getProfileDataFromLocal().id,
      quantity: 1,
    };
    CartAPI.addCartProduct(addProductToCartReqBody)
      .then((response) => {
        if (response.success === true) {
          // add product ID to state variable
          setIsProductInCart(true);
          return ToastMessage.show("Product added to cart.");
        } else {
          return ToastMessage.show("Error adding product.");
        }
      })
      .catch((err) => {
        return ToastMessage.show("Error adding product.", err);
      });
  }

  useEffect(() => {
    // Scroll to top of page -> fail safe check for UI
    window.scrollTo(0, 0);

    // Get all product details from DB
    getProductDetails();

    // Get Cart from Local
    const cartProducts = JSON.parse(localStorage.getItem("cartProducts")!);
    if (cartProducts.map((e) => e.productId).includes(id))
      setIsProductInCart(true);

    // Get Whishlist from local
    const wishlistedIds = JSON.parse(localStorage.getItem("wishlist")!);
    if (wishlistedIds.includes(id)) setIsWishlisted(true);
  }, []);
  return (
    <div className={Styles.productPage}>
      <AppBar />
      <ProductCarousel imagesList={productImages} />
      <div className={Styles.productTitle}>
        {productDetails["productTitle"]}
      </div>
      <div
        className={`${Styles.productPricing} d-flex justify-content-between`}
      >
        <span>
          &#8377;{productDetails["sellingPrice"]}&nbsp;&nbsp;
          <span className={Styles.productCardRegularPrice}>
            MRP <del>&#8377;{productDetails["regularPrice"]}</del>
          </span>
        </span>
        <span className={"float-end"}>
          <Rating
            rating={productDetails.ratingValue}
            voteCount={productDetails.ratingCount}
          />
        </span>
      </div>
      {productDetails ? (
        <div
          onClick={(e) => {
            if (isProductInCart) navigate("/cart");
            else addProductToCart(e, productDetails.id);
          }}
        >
          <Button
            disabled={
              productDetails["stockStatus"] === "outofstock" ? true : false
            }
            style={
              productDetails["stockStatus"] !== "outofstock"
                ? primaryButtonStyle
                : primaryButtonDisabledStyle
            }
            size={LARGE}
          >
            {isProductInCart === true
              ? "Go To Cart"
              : productDetails["stockStatus"] === "outofstock"
              ? "Out of stock"
              : "Add to cart"}
          </Button>
        </div>
      ) : (
        ""
      )}

      <div className="mt-3"></div>
      <div
        onClick={
          isWishlisted ? removeProductFromWishlist : addProductToWishlist
        }
      >
        <Button
          disabled={false}
          style={primaryButtonLightStyle}
          hoveredStyle={primaryButtonLightStyle}
          size={LARGE}
        >
          {isWishlisted ? "Remove from Wishlist" : "Add to Wishlist"}
        </Button>
      </div>

      <div className="mt-4"></div>
      <Heading size={SMALL} align={LEFT} title="Manufacturer" />
      <div
        className={Styles.contentInfo}
        dangerouslySetInnerHTML={{ __html: productDetails["manufactureInfo"] }}
      ></div>

      <div className="mt-4"></div>
      <Heading size={SMALL} align={LEFT} title="Product Details" />
      <div
        className={Styles.contentInfo}
        dangerouslySetInnerHTML={{ __html: productDetails["detailedInfo"] }}
      ></div>

      <div className="mt-5"></div>
      <Heading size={LARGE} align={LEFT} title="Similar Products" />

      {similarProducts ? (
        <ProductMiniCard productsList={similarProducts} />
      ) : (
        <Loading />
      )}

      <br></br>
    </div>
  );
}
