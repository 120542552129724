import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
const ThemeContext = createContext("light");
export default function TestPage(props) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => prevCount + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return <div>Count: {count}</div>;
}
