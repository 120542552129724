import { validateResponse } from "utils/apiValidations";
import { banners_getAll, util_verifyReferralCode } from "./apiEndpoints";
import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";
import { apiResponse } from "./interface";

export const UtilAPI = {
  verifReferralCode: async function (referralCode = "na", cancel = false) {
    const response = await api.request({
      url: util_verifyReferralCode + referralCode,
      method: "GET",
      signal: cancel
        ? cancelApiObject[
            this.verifReferralCode.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
};

// defining the cancel API object for ContentAPI
const cancelApiObject = defineCancelApiObject(UtilAPI);
