import React, { useRef, useState } from "react";
import Styles from "./pathologyCart.module.scss";
import AppBar from "shared/appBar/appBar";
import Heading from "shared/heading/heading";
import { LEFT, SMALL } from "shared/constants";
import { Link } from "react-router-dom";
import Button from "shared/button/button";
import { primaryButtonStyle } from "shared/buttonStyles";
import { LARGE } from "shared/buttonSize";
import CouponChecker from "shared/couponChecker/couponChecker";
import NotFound from "shared/notFound/notFound";

export default function PathologyCart() {
  let TestList = useRef<any>([]);
  const walletBalance = 0;

  TestList.current = [
    {
      id: "123456789",
      testName: "TestName Goes Here",
      partnerLab: "Kapeefit Partner",
      testNote: "10-12 Hr fasting is required",
      pricePreGst: 123.4,
      gstSlab: 5,
      qty: 3,
    },
    {
      id: "789456132",
      testName: "TestName2 Name Goes Here",
      partnerLab: "Lab Partner",
      pricePreGst: 456.2,
      gstSlab: 12,
      qty: 5,
    },
  ];

  let gstAmount_5 = 0;
  let gstAmount_12 = 0;
  let gstAmount_18 = 0;
  let gstAmount_28 = 0;
  let billGrandTotal = 0;

  function updateGstAmount(product: any) {
    let amount = product.pricePreGst * product.qty;
    switch (product.gstSlab) {
      case 5:
        gstAmount_5 = amount * (5 / 100);
        break;
      case 12:
        gstAmount_12 = amount * (12 / 100);
        break;
      case 18:
        gstAmount_18 = amount * (18 / 100);
        break;
      case 28:
        gstAmount_28 = amount * (28 / 100);
        break;
      default:
        amount = 0; // Zero gst case;
    }
  }

  function couponCodeCallback(couponStatusData: boolean) {
    console.log(couponStatusData);
  }

  return (
    <>
      <AppBar title="Cart" />

      {TestList.current.length > 0 ? (
        <div>
          <div className={`${Styles.headingLabelsContainer} d-flex`}>
            <div className={Styles.cartItemLabel}>
              Cart Item ({TestList.current.length})
            </div>
            <div className={Styles.modifyTest}>
              <Link to={"/cart/pathologyCart/modify"}></Link>Modify Test/Patient
            </div>
          </div>

          <hr />
          {TestList.current.map((pathTest, index) => {
            return (
              <div className={`${Styles.test} d-flex mt-2`} key={index}>
                <div className={Styles.testInfoContainer}>
                  <div className={Styles.testTitle}>{pathTest.testName}</div>
                  <div className={Styles.pathalogyPartner}>
                    {pathTest.partnerLab}
                  </div>
                  {pathTest.testNote && pathTest.testNote.length !== 0 ? (
                    <div className={Styles.testRequirements}>
                      <span className="material-symbols-outlined">warning</span>{" "}
                      10-12 Hr of fasting is required*
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className={Styles.testAmountContainer}>
                  <div className={Styles.testAmount}>
                    &#8377;{(pathTest.pricePreGst * pathTest.qty).toFixed(2)}
                  </div>
                  <div className={Styles.testLable}>
                    <span className="material-symbols-outlined">group</span>{" "}
                    {pathTest.qty} x {pathTest.pricePreGst}
                  </div>
                </div>
              </div>
            );
          })}

          {/* Shipping Address Details */}
          <div className="mt-4">
            <div className="d-flex">
              <Heading
                size={SMALL}
                align={LEFT}
                title="Sample Collection Address"
                width={"70%"}
              />
              <div className={Styles.rightViewMore}>
                <Link to={"/shop"}>Change Address</Link>
              </div>
            </div>
            <div className={Styles.address}>
              AMan Kishore Agarwal <br></br>abc/123, bareilly, Uttar Pradesh, -
              243122, abdfk jabsd kjfbakjsbdfjkab skdb.
            </div>
          </div>

          {/* Cupon Code Checker */}
          <CouponChecker handleCallback={couponCodeCallback} />

          {/* Wallet Selection */}
          <div className={`${Styles.walletSelection} d-flex mt-3`}>
            <div className={Styles.walletSelectionContent}>
              <b>Wallet</b>
              <br></br>
              <span>Your wallet balance is {walletBalance}</span>
            </div>
            <div className={Styles.walletCheckbox}>
              <input type="checkbox" />
            </div>
          </div>

          <div className="mt-4"></div>
          {/* Total Amount Calculations */}
          <div>
            <Heading
              size={LARGE}
              align={LEFT}
              title="Total Amount"
              width={"70%"}
            />
            <div className={`${Styles.billDetailsContainer} mt-2`}>
              {TestList.current.map((pathTest, index) => {
                updateGstAmount(pathTest);
                billGrandTotal +=
                  gstAmount_5 +
                  gstAmount_12 +
                  gstAmount_18 +
                  gstAmount_28 +
                  pathTest.pricePreGst * pathTest.qty;
                return (
                  <div
                    className={`${Styles.billProductRow} d-flex`}
                    key={index}
                  >
                    <div className={Styles.billProductName}>
                      {pathTest.testName}
                    </div>
                    <div className={Styles.billProductQty}>
                      X {pathTest.qty}
                    </div>
                    <div className={Styles.billProductTotal}>
                      {(pathTest.pricePreGst * pathTest.qty).toFixed(2)}
                    </div>
                  </div>
                );
              })}

              {/* GST Tax Rows */}
              <div className="mt-3"></div>
              {gstAmount_5 !== 0 ? (
                <div className={`${Styles.gstAmountRow} d-flex`}>
                  <div className={Styles.billGstTitle}>GST @ 5%</div>
                  <div className={Styles.billGstValue}>
                    &#8377;{gstAmount_5.toFixed(2)}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {gstAmount_12 !== 0 ? (
                <div className={`${Styles.gstAmountRow} d-flex`}>
                  <div className={Styles.billGstTitle}>GST @ 12%</div>
                  <div className={Styles.billGstValue}>
                    &#8377;{gstAmount_12.toFixed(2)}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {gstAmount_18 !== 0 ? (
                <div className={`${Styles.gstAmountRow} d-flex`}>
                  <div className={Styles.billGstTitle}>GST @ 18%</div>
                  <div className={Styles.billGstValue}>
                    &#8377;{gstAmount_18.toFixed(2)}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {gstAmount_28 !== 0 ? (
                <div className={`${Styles.gstAmountRow} d-flex`}>
                  <div className={Styles.billGstTitle}>GST @ 28%</div>
                  <div className={Styles.billGstValue}>
                    &#8377;{gstAmount_28.toFixed(2)}
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className={`${Styles.billTotal} d-flex`}>
                <div className={Styles.billTotalLabel}>Total</div>
                <div className={Styles.billTotalValue}>{billGrandTotal}</div>
              </div>
            </div>
          </div>

          <div className={Styles.proceedButton}>
            <Button
              disabled={false}
              style={primaryButtonStyle}
              hoveredStyle={primaryButtonStyle}
              size={LARGE}
            >
              Proceed to checkout
            </Button>
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
}
